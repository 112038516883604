import React, { Component } from 'react';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="privacy-policy">
                <h3>Privacy Policy</h3>
                <p>
                    This Privacy Policy explains how Tribes3Ladder ("we," "us," or "our") collects, uses, and safeguards the information provided by users ("you," "your") of our web application. We are committed to protecting your privacy and ensuring the security of the information you share with us.
                </p>
                <h3>Information We Collect</h3>
                <p>
                    We collect and store the following information:
                </p>
                <ol>
                    <li>
                        <strong>Email Address:</strong> We collect your email address when you sign up for an account on Tribes3Ladder. This information is used to verify your account and send you important notifications related to your account.
                    </li>
                    <li>
                        <strong>Verification Token:</strong> We generate a unique verification token when you sign up for an account. This token is included in the verification link sent to your email address. It is used to verify your account during the registration process.
                    </li>
                    <li>
                        <strong>Password:</strong> Your password is one way hashed and will never be visible to anyone.
                    </li>
                </ol>
                <h3>How We Use Your Information</h3>
                <p>
                    We use the information you provide in the following ways:
                </p>
                <ol>
                    <li>
                        <strong>Account Verification:</strong> Your email address is used for account verification purposes. We send you a verification link to confirm your registration.
                    </li>
                    <li>
                        <strong>Notifications:</strong> We may send you important notifications related to your account, such as password resets and account updates.
                    </li>
                </ol>
                <h3>Sharing Your Information</h3>
                <p>
                    We do not share your information with third parties or sell your information.
                </p>
                <h3>Security</h3>
                <p>
                    We take security measures to protect your information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its security.
                </p>
                <h3>Changes to This Privacy Policy</h3>
                <p>
                    This Privacy Policy is subject to change at our discretion. We will notify you of any changes by posting the updated Privacy Policy on this page.
                </p>
                <p>
                    Last Updated: 07/03/2024
                </p>
            </div>
        );
    }
}

export default PrivacyPolicy;
