import config from './Config/config.json';



export async function getApiData(apiType, params) {

    try {
        var baseURL = ""
        if (!window.location.href.includes("localhost"))
            baseURL = "https://api.tribes3ladder.com"

        var url = baseURL + config[apiType];

        var replaceOccurences = (url.match(/<<REPLACEME>>/g) || []).length;
        for (var i = 0; i < replaceOccurences; i++) {
            if (params[i] === undefined || params[i] === null)
                url = url.replace("/<<REPLACEME>>", "");
            else
                url = url.replace("<<REPLACEME>>", params[i]);

        }

        const settings =
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }
        url = url.replace(/\/$/, '');
        var apiData = await fetch(url, settings)
        apiData = await apiData.json()
        return apiData;

    }

    catch (e) {

        return e;

    }



}


export async function postApiData(apiType, params) {
    var baseURL = ""
    if (!window.location.href.includes("localhost"))
        baseURL = "https://api.tribes3ladder.com"

    var url = baseURL + config[apiType];

    const settings =

    {
        method: 'POST',

        headers: {

            'Accept': 'application/json',

            'Content-Type': 'application/json'

        },

        body: JSON.stringify(params[0])
    }
    var apiData = await fetch(url, settings)
        .then((res) => res.json())
        .then((json) => {
            return json;
        })
    return apiData;
}


export async function postApiFormData(apiType, formData) {
    var baseURL = ""
    if (!window.location.href.includes("localhost"))
        baseURL = "https://api.tribes3ladder.com"

    var url = baseURL + config[apiType];

    const settings =

    {
        method: 'POST',
        body: formData,
    }

    var apiData = await fetch(url, settings)
        .then((res) => res.json())
        .then((json) => {
            return json;
        })
    return apiData;
}


export default getApiData