import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo from './../Images/T3l-logo.png';
import { Layout } from './Layout';
import MainLadder from './MainLadder'
import { getApiData, postApiData } from '../fetchApi';
import moment from 'moment';

class LandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            upcomingMatches: [],
        };
    }

    componentDidMount = async () => {
        const upcomingMatches = await getApiData('GET_UPCOMING_MATCHES');
        this.setState({ upcomingMatches});
    }

    render() {
        return (
            <Layout showNavMenu={false}>
                <Container className="text-center">
                    <Row>
                        <Col>
                            <img className="logo logo-landing" src={Logo}></img>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <a href="/login" className="btn btn-primary">Sign in</a>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <p style={{ lineHeight: "2.3em" }}>
                                We are thrilled to announce the launch of the first-ever Tribes 3 Rivals ladder! This ladder is designed to bring a new level of competitive gameplay to the Tribes community, allowing players to test their skills against each other in an organized and exciting format.
                            </p>
                        </Col>
                    </Row>
                    <br />
                    <Row className="bootstrap-grid">
                        <Col sm={12}>
                            <h4>Upcoming Matches</h4>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Team A</th>
                                        <th>Team B</th>
                                        <th>Match Date</th>
                                        <th>Maps</th>
                                        <th>Format</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.upcomingMatches.length > 0 ? (

                                        this.state.upcomingMatches.map((match) => (
                                            <tr key={match.id}>
                                                <td>{match.initiatingTeamName}</td>
                                                <td>{match.opponentName}</td>
                                                <td> {moment(match.matchDate).format('MM/DD/YYYY hh:mm A')} EST</td>
                                                <td>{match.maps}</td>
                                                <td>{match.formatName}</td>
                                            </tr>
                                        ))
                                    ) : <tr>
                                        <td colSpan="5" style={{ textAlign: 'center' }}>No upcoming matches found</td>
                                    </tr>}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <MainLadder></MainLadder>
                    </Row>
                    <br />
                   
                   
                   
                   
                </Container>
            </Layout>
        );
    }
}

export default LandingPage;
